import { Controller } from "stimulus"
import Trix from "trix"

export default class extends Controller {
  static targets = [ "output", "errors", "commentform", "wrapper", "record_type" ]

  connect() {
    this.editor = this.outputTarget.editor
    this.setupTrixToolbar()
  }

  disconnect() {
    document.removeEventListener("trix-action-invoke", this.handleAnswerAction.bind(this))
    document.removeEventListener("trix-attachment-remove", this.handleAnswerRemove.bind(this))
  }

  setupTrixToolbar() {
    let button = document.createElement("button");
    button.setAttribute("type", "button");
    button.setAttribute("title", "Embed User profile with a comment");
    button.setAttribute("data-trix-action", "x-answer");
    button.setAttribute("data-toggle", "modal");
    button.setAttribute("data-target", "#addAnswerModal");
    button.classList.add("trix-button");
    button.innerHTML = "Add answer";

    document.addEventListener("trix-action-invoke", this.handleAnswerAction.bind(this))
    document.addEventListener("trix-attachment-remove", this.handleAnswerRemove.bind(this))
    this.wrapperTarget.querySelector(".trix-button-group--block-tools").appendChild(button);
  }

  handleAnswerAction(event) {
    if(event.actionName === "x-answer") {
    }
  }

  handleAnswerRemove(event) {
    let attachable = event.attachment.getAttributes()
  }

  update(event) {
    let [data, status, xhr] = event.detail
    this.commentformTarget.querySelector('.close').click()
    this.commentformTarget.querySelector('form').reset()
    document.querySelector("trix-editor").focus()
    var attachment = new Trix.Attachment({ id: data.id, sgid: data.sgid, content: data.content })
    this.editor.insertAttachment(attachment)
    this.editor.insertString(" ")
  }

  unprocessableEntity(event) {
    let [data, status, xhr] = event.detail
    if (this.errorsTarget) {
      this.errorsTarget.innerHTML = data.errors
    }
  }
}
