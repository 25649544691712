import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ ]

  scroll() {
    // var elementPosition = $('#agenda_days').offset()
//     var endElementPosition = $('#endagenda').offset()

    // if(($(window).scrollTop() > elementPosition.top - 110) && ($(window).scrollTop() < endElementPosition.top - 110)){
//       // if ($('#day_navigation').hasClass('d-none')) {
// //         $('#day_navigation').removeClass('d-none')
// //       }
//     } else {
//       $('#day_navigation li').removeClass('active')
//     }
  }
}
