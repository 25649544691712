import { Controller } from "stimulus"
import Trix from "trix"

export default class extends Controller {
  static targets = [ "output", "wrapper", "errors", "embedmodal"]

  connect() {
    this.editor = this.outputTarget.editor
    this.setupTrixToolbar()
  }

  disconnect() {
    document.removeEventListener("trix-action-invoke", this.handleEmbedAction.bind(this))
    document.removeEventListener("trix-attachment-remove", this.handleEmbedRemove.bind(this))
  }

  setupTrixToolbar() {
    let button = document.createElement("button");
    button.setAttribute("type", "button");
    button.setAttribute("title", "Embed YouTube");
    button.setAttribute("data-trix-action", "x-embed");
    button.setAttribute("data-toggle", "modal");
    button.setAttribute("data-target", "#embedModal");
    button.classList.add("trix-button");
    button.innerHTML = "YouTube";

    document.addEventListener("trix-action-invoke", this.handleEmbedAction.bind(this))
    document.addEventListener("trix-attachment-remove", this.handleEmbedRemove.bind(this))
    this.wrapperTarget.querySelector(".trix-button-group--block-tools").appendChild(button);
  }

  handleEmbedAction(event) {
    if(event.actionName === "x-embed") {
    }
  }

  handleEmbedRemove(event) {
    let attachable = event.attachment.getAttributes()
  }

  update(event) {
    let [data, status, xhr] = event.detail
    this.embedmodalTarget.querySelector('.close').click()
    this.embedmodalTarget.querySelector('form').reset()

    var attachment = new Trix.Attachment({
      sgid: data.sgid,
      content: data.content
    })
    this.editor.insertAttachment(attachment)
    this.editor.insertString(" ")
  }

  unprocessableEntity(event) {
    let [data, status, xhr] = event.detail
    if (this.errorsTarget) {
      this.errorsTarget.innerHTML = data.errors
    }
  }
}
