import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "field", "preview" ]

  add_photos(event) {
    this.fieldTarget.click()
  }

  handleFiles(event) {
    const input = this.fieldTarget;
    this.previewTarget.innerHTML = "";

    const divRow = document.createElement("div");
    divRow.className = 'row mt-3'
    this.previewTarget.appendChild(divRow);

    for (let i = 0; i < input.files.length; i++) {
      const divCol = document.createElement("div");
      divCol.className = "col-3 px-1";
      divRow.appendChild(divCol);

      const img = document.createElement("img");
      img.src = URL.createObjectURL(input.files[i]);
      img.className = "p-2 bg-white img-fluid";
      img.onload = () => {
        URL.revokeObjectURL(img.src);
      };
      divCol.appendChild(img);
    }

  }
}
