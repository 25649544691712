import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "entries", "pagination" ]

  initialize() {
    this.loading = false
  }

  scroll() {
    let a = this.paginationTarget.querySelector("a[rel='next']")
    if (a == null) { return }

    let body = document.body,
      html = document.documentElement
    
    let height = Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight)
    
    if (window.pageYOffset >= (height - window.innerHeight - 200)) {
      a.click()
    }
  }

  appendItems(event) {
    let [data, status, xhr] = event.detail
    this.entriesTarget.insertAdjacentHTML('beforeend', data.entries)
    this.paginationTarget.innerHTML = data.pagination
  }

  completed(event) {
    this.loading = false
  }
}
