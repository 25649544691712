import { Controller } from "stimulus"
import Rails from '@rails/ujs'
import autocomplete from 'autocompleter'

export default class extends Controller {
  static targets = [ "autocompleteinput", "autocompletehiddeninput", "recordtype", "city", "category" ]

  connect() {
    let url = this.data.get('url')

    if (url == null) {
      url = "/user_suggestions"
    }

    url = new URL(url, window.location.protocol + "//" + window.location.host)

    autocomplete({
      input: this.autocompleteinputTarget,
      preventSubmit: true,
      fetch: function(text, update) {
        text = text.toLowerCase();
        url.searchParams.set('q[name_cont]', text);

        Rails.ajax({
          url: url,
          type: "get",
          success: function(data) {
            update(data.entries)
          }
        })
      },
      onSelect: this.onSelected.bind(this)
    })
  }

  onSelected(item, ele) {
    if (this.hasCategoryTarget) {
      let values = this.categoryTarget.choiceControl.getValue(true)
      let newArray = []

      if (values.length == 0) {
        newArray = item.categories
      }
      else {
        values.forEach(function(value, index, array) {
          item.categories.forEach(function(category, i) {
            if (value != category.value) {
              newArray.push(category)
            }
          })
        })
      }

      this.categoryTarget.choiceControl.setValue(newArray)
    }

    if (this.hasCityTarget) {
      let values = this.cityTarget.choiceControl.getValue(true)
      let newArray = []

      if (values.length == 0) {
        newArray = item.cities
      }
      else {
        values.forEach(function(value, index, array) {
          item.cities.forEach(function(city, i) {
            if (value != city.value) {
              newArray.push(city)
            }
          })
        })
      }

      this.cityTarget.choiceControl.setValue(newArray)
    }

    ele.value = item.label
    this.autocompletehiddeninputTarget.value = item.value
    if (this.hasRecordtypeTarget)
      this.recordtypeTarget.value = item.record_type
  }
}
