import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "controls", "errors" ]

  updateControls(event) {
    let [data, status, xhr] = event.detail
    this.controlsTarget.innerHTML = data.controls
  }

  unprocessableEntity(event) {
    let [data, status, xhr] = event.detail
    if (this.errorsTarget) {
      this.errorsTarget.innerHTML = data.errors
    }
  }
}
