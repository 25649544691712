import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "source", "message" ]

  connect() {
    if (document.queryCommandSupported("copy")) {
      this.element.classList.add("clipboard--supported")
    }
  }

  copy(event) {
    event.preventDefault()
    this.sourceTarget.select()
    document.execCommand("copy")
    this.messageTarget.innerHTML = 'Link copied.'
  }
}
