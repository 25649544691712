import { Controller } from "stimulus"

var jitsi_api

export default class extends Controller {
  static targets = [ "meetingaction", "meetingtimings", "meet" ]

  // participantJoined
  // participantLeft
  // videoConferenceJoined
  // videoConferenceLeft
  // readyToClose

  startMeeting(event) {
    event.preventDefault()

    if(jitsi_api) { jitsi_api.dispose() }
    this.meetTarget.style.display = 'block'

    const domain = 'meet.jit.si'
    const options = {
      roomName: this.meetingactionTarget.dataset.meetingId,
      width: '100%',
      height: 500,
      parentNode: this.meetTarget,
      userInfo: {
        displayName: this.meetingactionTarget.dataset.meetingDisplayname
      }
    };
    jitsi_api = new JitsiMeetExternalAPI(domain, options)
    jitsi_api.executeCommand('subject', this.meetingactionTarget.dataset.meetingSubject)
    jitsi_api.executeCommand('password', this.meetingactionTarget.dataset.meetingPwd)

    this.meetingtimingsTarget.style.display = 'none'
    this.meetingactionTarget.style.display = 'none'

    jitsi_api.addListener('videoConferenceJoined', function(event) {
      console.log('============================videoConferenceJoined====================================')
    })

    jitsi_api.addListener('videoConferenceLeft', function(event) {
      console.log('============================videoConferenceLeft====================================')
    })

    jitsi_api.addListener('participantJoined', function(event) {
      console.log('============================participantJoined====================================')
    })

    jitsi_api.addListener('participantLeft', function(event) {
      console.log('============================participantLeft====================================')
    })

    jitsi_api.addListener('readyToClose', () => {
      console.log('============================readyToClose====================================')
      this.meetingtimingsTarget.style.display = 'block'
      this.meetingactionTarget.style.display = 'inline-block'
      this.meetTarget.style.display = 'none'
    })
  }

}
