import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "collapsible" ]

  connect() {
    if (this.collapsibleTarget != undefined) {
      this.collapsibleTarget.style.display = 'none'
    }
  }

  toggle() {
    if (this.collapsibleTarget.style.display == 'block') {
      this.collapsibleTarget.style.display = 'none'
    } else {
      this.collapsibleTarget.style.display = 'block'
    }
  }
}
