import { Controller } from "stimulus"
import Trix from "trix"
import Tribute from "tributejs"

export default class extends Controller {
  static targets = [ "field" ]

  connect() {
    this.editor = this.fieldTarget.editor
    this.initializeTribute()
  }

  disconnect() {
    this.tribute.detach(this.fieldTarget)
  }

  initializeTribute() {
    this.tribute = new Tribute({
      allowSpaces: true,
      values: this.fetchUsers,
    })

    this.tribute.attach(this.fieldTarget)
    this.tribute.range.pasteHtml = this._pasteHtml.bind(this)
    this.fieldTarget.addEventListener("tribute-replaced", this.replaced)
  }

  fetchUsers(text, callback) {
    fetch(`/mentions.json?q[name_cont]=${text}&trigger=${this.trigger}`)
      .then(response => response.json())
      .then(res => callback(res.entries))
      .catch(error => callback([]))
  }

  replaced(e) {
    let mention = e.detail.item.original
    let attachment = new Trix.Attachment({
      sgid: mention.sgid,
      content: mention.content
    })
    this.editor.insertAttachment(attachment)
    this.editor.insertString(" ")
  }

  _pasteHtml(html, startPos, endPos) {
     let range = this.editor.getSelectedRange()
     let position = range[0]
     let length = endPos - startPos

     this.editor.setSelectedRange([position - length, position])
     this.editor.deleteInDirection("backward")
  }
}
