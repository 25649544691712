import { Controller } from "stimulus"
import Rails from '@rails/ujs'
import autocomplete from 'autocompleter'

export default class extends Controller {
  static targets = [ "autocompleteinput", "autocompletehiddeninput" ]

  connect() {
    var hiddenInput = this.autocompletehiddeninputTarget
    var url = this.data.get('url')
    if (url == null)
      url = "/user_suggestions?term="
    
    autocomplete({
      input: this.autocompleteinputTarget,
      preventSubmit: true,
      fetch: function(text, update) {
        text = text.toLowerCase();

        Rails.ajax({
          url: url + text,
          type: "get",
          success: function(data) {
            update(data.entries.users)
          }
        })
      },
      onSelect: function(item) {
        this.input.value = item.label
        hiddenInput.value = item.value
      }
    })
  }

}
