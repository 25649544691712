// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import 'bootstrap'
import 'trix'
import 'timepicker/jquery.timepicker'

import Rails from '@rails/ujs'
Rails.start()

import Turbolinks from 'turbolinks'
Turbolinks.start()

import LocalTime from "local-time"
LocalTime.start()

// require("@rails/activestorage").start()
// import "channels"

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

require("@rails/actiontext")

import "controllers"

import "app"

var FBInit;

FBInit = function() {
  FB.init({
    appId: '301552606609065',
    xfbml: true,
    version: 'v3.0'
  });
  $('#permanent').append($('#fb-root').detach());
};

$(document).on('turbolinks:load', function(event) {
  if (typeof FB !== 'undefined' && FB !== null) {
    FB.XFBML.parse();
  } else {
    $.getScript('//connect.facebook.net/en_US/sdk.js#xfbml=1&version=v3.0', FBInit);
  }
  scrollOnPageLoad();
});

$(document).on('turbolinks:before-cache', function() {
  $('[data-turbolinks-no-cache]').remove();
});

function scrollOnPageLoad() {
  // to top right away
  if (window.location.hash) scroll(0, 0);
  // void some browsers issue
  setTimeout(scroll(0, 0), 1);
  var hashLink = window.location.hash;
  if ($(hashLink).length) {
    $(function () {
        // *only* if we have anchor on the url
        // smooth scroll to the anchor id
        $('html, body').animate({
          scrollTop: $(window.location.hash).offset().top - 120
        }, 2000);
    });
  }
}
