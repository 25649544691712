import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "content" ]

  connect() {
    const collection = this.contentTarget.querySelectorAll("action-text-attachment[content-type='application/octet-stream'] .person")

    const div = document.createElement('div')
    div.classList.add('d-flex')
    div.classList.add('my-3')
    div.classList.add('pb-3')
    div.classList.add('border-bottom')
    for(const person of collection) {
      const img = person.querySelector(".avatar > img")
      const imgDiv = document.createElement('div')
      imgDiv.classList.add('mr-2')
      const imgClone = img.cloneNode(true)
      imgClone.dataset.offset = person.offsetTop
      imgClone.style.cursor = "pointer"
      const name = imgClone.getAttribute("title")
      imgClone.setAttribute("Title", `Click to see ${name}'s answer`)
      imgDiv.appendChild(imgClone)

      imgClone.addEventListener("click", this.handleAvatarClickAction.bind(this))

      div.appendChild(imgDiv)
    }

    const firstAttachment = this.contentTarget.querySelector("action-text-attachment[content-type='application/octet-stream']")
    firstAttachment.parentNode.insertBefore(div, firstAttachment);
  }

  handleAvatarClickAction(event) {
    window.scroll({
      top: parseInt(event.target.dataset.offset) + 150,
      left: 0,
      behavior: "smooth",
    });
  }
}
