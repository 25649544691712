import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "preview" ]

  fileSelected(event) {
    var input = event.target
    var output = this.previewTarget

    if (input.files && input.files[0]) {
      var reader = new FileReader()
        reader.onload = function () {
         output.src = reader.result
       }
       reader.readAsDataURL(input.files[0])
    }
  }

}
