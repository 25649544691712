import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "newform", "errors", "newformlink", "employeeslist" ]

  renderNewEmployeeForm(event) {
    let [data, status, xhr] = event.detail
    this.newformTarget.innerHTML = data.controls
    this.newformlinkTarget.style.display = "none"
  }

  unprocessableEntity(event) {
    let [data, status, xhr] = event.detail
    this.newformTarget.innerHTML = data.errors
  }

  hideForm(event) {
    this.resetForm(this.newformTarget)
    this.toggle(this.newformlinkTarget)
  }

  create(event) {
    let [data, status, xhr] = event.detail
    this.employeeslistTarget.insertAdjacentHTML('beforeend', data.entries)
    this.toggle(this.newformlinkTarget)
    this.resetForm(this.newformTarget)
  }

  show(elem) {
    elem.style.display = 'block'
  }

  hide(elem) {
    elem.style.display = 'none'
  }

  toggle(elem) {

    if (window.getComputedStyle(elem).display === 'block') {
      this.hide(elem)
      return
    }

    this.show(elem)
  }

  resetForm(ele){
    ele.innerHTML = ""
  }
}
